import React from "react"
import { graphql } from "gatsby"

import PrivacyPolicy from "../components/v1/Page-Components/PrivacyPolicy"
import UserAgreement from "../components/v1/Page-Components/UserAgreement"
import AboutPage from "../components/v2/PageComponents/AboutPage"
import NewsPage from "../components/v2/PageComponents/NewsPage"
import PressPage from "../components/v2/PageComponents/PressPage"
import ProductsPage from "../components/v2/PageComponents/ProductsPage"
import EnergyPage from "../components/v2/PageComponents/EnergyPage"
import RequestDemoPage from "../components/v2/PageComponents/RequestDemoPage"
import SupportPage from "../components/v2/PageComponents/SupportPage"
import TeamPage from "../components/v2/PageComponents/TeamPage"
import ContractorsDirectPage from "../components/v2/PageComponents/ContractorsDirectPage"

const WpPageTemplate = ({ data }) => {
  const page = data?.allWpPage?.edges[0]?.node || {}

  if (page.slug === "about") {
    return <AboutPage />
  }
  else if (page.slug === "news") {
    return <NewsPage />
  }
  else if (page.slug === "press") {
    return <PressPage />
  }
  else if (page.slug === "mickey-energy") {
    return <EnergyPage />
  }
  else if (page.slug === "products") {
    return <ProductsPage />
  }
  else if (page.slug === "request-demo") {
    return <RequestDemoPage />
  }
  else if (page.slug === "support") {
    return <SupportPage />
  }
  else if (page.slug === "team") {
    return <TeamPage />
  }
  else if (page.slug === "user-agreement") {
    return <UserAgreement />
  }
  else if (page.slug === "privacy-policy") {
    return <PrivacyPolicy />
  }
  else if (page.slug === "contractor-direct") {
    return <ContractorsDirectPage />
  }

  return (
    <>
      <h1 dangerouslySetInnerHTML={{ __html: page?.title }} />
      <div dangerouslySetInnerHTML={{ __html: page?.content }} />
    </>
  )
}

export const data = graphql`
  query WpPageQuery($slug: String!) {
    allWpPage(filter: { slug: { eq: $slug } }) {
      edges {
        node {
          id
          title
          content
          slug
        }
      }
    }
  }
`

export default WpPageTemplate
