import React, { useState } from 'react'
import styled from 'styled-components'
import Layout from '../Layout/layout2'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import { Grid, Header, Button, Tab, Input } from 'semantic-ui-react'
import ContractorsDirectPlaceholder from "../../../images/contractors-direct-placeholder.jpg"
import ContractorDirectLogo from "../../../images/contractor-direct-logo.png"
import EnergyBackground from "../../../videos/energy.webmhd.webm"
import { media } from "../../../../mq"
import SmallToMedium from "../../../images/small-to-medium.jpg"
import AutomatedTrading from "../../../images/automated-trading.jpg"
import PriceTransparency from "../../../images/price-transparency.jpg"
import MickeyTabs from '../../v2/UtilityComponents/MickeyTabs'
import ContractorsDirectFeatures from "../../../images/contractors-direct-features.jpg"
import RabensPng from "../../../images/Rabens.png"
import NoahPng from "../../../images/noah-crouse.png"
import JohnPlaceholder from "../../../images/john-placeholder.png"
import JeffreyCrouse from '../../../images/jeff-crouse.png';
import RequestImg from "../../../images/requestimg.webp"
import OrderManagement from "../../../images/contractors-order-management.png"
import RFQ from "../../../images/rfq.png"
import AutomatedLogistics from "../../../images/contractors-automated-logistics.png"
import ContractorsDirectBackgroundVideo from "../../../videos/contractors-direct-background.webmhd.webm"

const ContractorsDirectPageWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
  #video-wrapper {
        height: 100vh;
        width: inherit;
        overflow: inherit;
        position: relative;
        .gatsby-image-wrapper{
           height: calc(100vh - 65px);
        }
        .overlay-text_{
            top: 15vh;
            position: absolute;
            height: 100%;
            align-items: center;
            flex-wrap: nowrap;
            color: white;
            width: 100%;
        }
        .background-video {
            min-height: 100%;
            min-width: 100%;
        }
        .ui.grid{
            margin: 0 !important;
        }
    }
    .section-container{
        padding: 2rem 0 2rem 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        ${media.smallMedium`flex-direction: row;`}
        ${media.smaller`padding: 3rem 5rem;`}
        ${media.xl`justify-content: center;`}
        &#hero{
            h1{
                width: 100%;
                margin: 2rem 0 2rem 0;
                text-align: center;
                ${media.small`text-align: left;`}
            }

        }
        &#features{
        padding: 1rem 1rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        ${media.smallMedium`padding: 3rem 5rem;`}
        ${media.xl`justify-content: center;`}
        }
        &#leadership{
        flex-direction: column;
        min-height: 500px;
        align-items: center;
        justify-content: center;
        ${media.smallMedium`flex-direction: row;`}
        .leadership-left{
            margin-bottom: 2rem;
            min-height: inherit;
            ${media.smallMedium`margin-bottom: 0;`}
            .orange-button-wrapper{
                justify-content: center;
                ${media.small`justify-content: flex-start;`}
            }
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
                p{
                    font-size: 16px;
                    line-height: 2em;
                    margin-top: 2rem;
                    font-family: "Brown Regular";
                }
            }
        }
        .leadership-right{
            min-height: inherit;
            .images-wrapper{
                display: flex;
                min-height: inherit;
                flex-direction: column;
                align-items: center;
                margin-top: 3rem;
                ${media.small`flex-direction: row; margin-top: 0; align-items: baseline;`}
                ${media.smallMedium`align-items: unset;`}
                .image-left{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex: 0 1 50%;
                    padding: 0;
                    ${media.small`padding: .5rem;`}
                    ${media.smallMedium`padding: unset;`}
                    
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 3rem;
                        ${media.small`margin-bottom: 0;`}
                        img{
                            width: 300px;
                            height: auto;
                        }
                        .name{
                            margin-top: 1rem;
                            font-size: 18px;
                            .position{
                                font-family: "Brown Regular";
                                margin-top: .5rem;
                            }
                        }
                    }
                    
                }
                .image-right{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    flex: 0 1 50%;
                    padding: 0;
                    ${media.small`padding: .5rem;`}
                    ${media.smallMedium`padding: unset;`}
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 3rem;
                        ${media.small`margin-bottom: 0;`}
                        img{
                            width: 300px;
                            height: auto;
                        }
                        .name{
                            margin-top: 1rem;
                            font-size: 18px;
                            .position{
                                font-family: "Brown Regular";
                                margin-top: .5rem;
                            }
                        }
                    }
                    
                }
            }

        }
    }
    &#request-demo{
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            ${media.large`flex-direction: row;`}
            ${media.xl`justify-content: center;`}
            .sixteen.wide.column{
                margin: 0 0 4rem 0 !important;
                ${media.small`margin: 0 !important;`}
            }

            .request-demo-header{
                width: 100%;
                ${media.large`width: 80%;`}
                .request-demo-subheader{
                    font-size: 16px;
                    line-height: 1.8em;
                    margin-bottom: 2rem;
                    margin-top: 2rem;
                    font-family: "Brown Regular"
                }
            }
            .request-image-wrapper{
                text-align: center;
                margin-top: 4rem;
                ${media.large`text-align: right; margin-top: 0;`}
                    img{
                    width: 100%;
                    height: auto;
                }
            }
            .orange-button-wrapper{
                margin-bottom: 0;
                ${media.small`justify-content: flex-start;`}
            }
            .request-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                ${media.small`flex-direction: row;`}
                .ui.input{
                    border-radius: 50px;
                    border: 1px solid #6d6e70;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 2rem;
                    ${media.small`width: 300px; margin-right: 2rem; margin-bottom: 0;`}
                    input{
                        border-radius: 50px;
                        border: none;
                        width: inherit;
                    }
                }
            }
    }
    }
    h1,h2{
    font-size: 36px;
    ${media.smaller`font-size: 48px ;`}
}
.orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }

`

const ContractorsDirectPage = () => {

    const panes = [
        {
            menuItem: "Material List Builder",
            content: function () {
                return (
                    <Tab.Pane>
                        <Grid stackable width={16} style={{ justifyContent: "center", alignItems: "flex-start", paddingBottom: "4rem", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", marginTop: "50px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>Material List and RFQ's in one place
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "16px", marginTop: "2rem", fontFamily: "BrownLLSub-Regular", }}>
                                        Mickey | Contractor Direct offers a powerful portal for construction firms to access details of their project’s material list, including product fulfillment, shipment details, and order finances. The new division is also working on automating an RFQ process – from bid creation to acceptance and fulfillment.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                            <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: wd?.width <= 1145 ? "center" : "flex-end" }} width={wd?.width <= 1145 ? 16 : 10}>
                                <img
                                    style={{
                                        // maxHeight: wd?.width <= 1145 ? "auto" : "470px",
                                        minWidth: "300px",
                                        width: wd?.width <= 1145 ? "100%" : "auto",
                                        marginTop: wd?.width <= 1145 ? "4rem" : ""
                                    }}
                                    src={RFQ}
                                />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >
                )
            }
        },
        {
            menuItem: "Automated Fulfillment",
            content: function () {
                return (

                    < Tab.Pane>
                        <Grid stackable width={16} style={{ justifyContent: "center", alignItems: "flex-start", paddingBottom: "4rem", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", marginTop: "50px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    Flexible and Reliable Fulfillment Options
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "16px", marginTop: "2rem", fontFamily: "BrownLLSub-Regular", }}>
                                        Mickey has partnered with several of the country’s leading trucking and logistics leaders to streamline the pick-up and delivery process. After a contractor has accepted their bid, we can now begin scheduling and booking trucks all of which can be tracked directly on our platform. Our portal allows for groups of materials to be shipped together, as single truckloads, or divided across several trucks. This allows for greater delivery flexibility around the contractor’s building schedule.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                            <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: wd?.width <= 1145 ? "center" : "flex-end" }} width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={AutomatedLogistics} />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >

                )
            }
        },
        {
            menuItem: "Order Management",
            content: function (props) {
                return (
                    < Tab.Pane>
                        <Grid stackable width={16} style={{ justifyContent: "center", alignItems: "flex-start", paddingBottom: "4rem", flexDirection: wd.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", marginTop: "50px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    Keep your Projects Organized
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "16px", marginTop: "2rem", fontFamily: "BrownLLSub-Regular", }}>
                                        Easily manage and track orders through Mickey’s integrated platform. Users can create credit applications, review and download shipping and trucking documents, and keep track of all of order documents directly in the Mickey portal.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: wd?.width <= 1145 ? "center" : "flex-end" }} width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={OrderManagement} />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >
                )
            }
        }

    ]
    const wd = useWindowDimensions()
    const [email, setEmail] = useState("")
    const handleEmail = (inputEmail) => {
        setEmail(inputEmail)
    }

    return (
        <Layout>
            <ContractorsDirectPageWrapper>
                {wd && wd?.width && <>

                    <div id="video-wrapper">
                        {
                            wd.width <= 540 &&
                            <div className='gatsby-image-wrapper'>
                                <img src={ContractorsDirectPlaceholder} />
                            </div>
                        }
                        {
                            wd.width > 540 &&
                            <video
                                className='background-video'
                                preload
                                loop
                                muted
                                autoPlay
                            >
                                <source src={ContractorsDirectBackgroundVideo} type="video/webm" />
                            </video>
                        }
                        <Grid
                            className='overlay-text_'
                            style={{
                                flexDirection: "column",
                                top: wd.width <= 950 && "10vh"
                            }}
                            width={16}
                        >
                            <Grid.Column width={(wd.width !== (null || undefined || "")) && ((wd.width <= 950 && wd.width >= 768 && 10) || (wd.width < 768 && 13) || wd.width > 950 && 10)} style={{ marginBottom: "1.5rem" }}>
                                <img
                                    src={ContractorDirectLogo}
                                />
                                {/* <Header textAlign='center' className='digital' as={"h1"} style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)', fontSize: wd.width <= 540 ? "40px" : "72px", color: "inherit" }}>
                                    Mickey | Contractor Direct
                                </Header> */}
                            </Grid.Column>
                            <Grid.Column width={(wd.width <= 950 && wd.width >= 768 && 10) || (wd.width < 768 && 13) || wd.width > 950 && 8} style={{ marginBottom: "1.5rem" }}>
                                <Header textAlign='center' className='mission' as={"p"} style={{ fontSize: "20px", color: "inherit" }}>
                                    {/* MAKE SURE WE MAKE THE TEXT RESPONSIVE */}

                                    Mickey | Contractor Direct assists contractors with building project order fulfillment – from product procurement to shipping updates and online payment functionality.
                                </Header>
                            </Grid.Column>
                            {/* <Grid.Column textAlign='center' width={8} style={{ display: "flex", justifyContent: "center", marginBottom: "5rem" }}>
                            <Button href="/about" className='orange-button'>
                                Learn More
                            </Button>
                        </Grid.Column> */}
                        </Grid>
                    </div>
                    <Grid className='section-container' id="hero">
                        <Header as={"h1"}>
                            Contractor Direct Features
                        </Header>
                        <MickeyTabs data={panes} />
                    </Grid>
                    <Grid className='section-container' id="features">
                        <img src={ContractorsDirectFeatures} />
                    </Grid>
                    <Grid
                        stackable
                        width={wd.width >= 1600 ? 14 : 16}
                        className="section-container"
                        id="leadership"
                    >

                        <Grid.Column className='leadership-left' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                            <Header as={"h2"}>
                                Strong Leadership.<br />
                                Discerning Vision.
                                <Header.Subheader as={"p"}>
                                    Mickey was founded by Alex Rabens who leads the company as its CEO. Jeffrey Crouse joined the team as its Vice President of Trading, along with Noah Crouse as Associate Trader, for the Contractor Direct division.
                                </Header.Subheader>
                            </Header>
                            <div className='orange-button-wrapper'>
                                <Button
                                    className="orange-button"
                                    href="/team"
                                    style={{
                                        height: "40px"
                                    }}
                                >
                                    Meet Our Entire Team
                                </Button>
                            </div>
                        </Grid.Column>
                        <Grid.Column className='leadership-right' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>

                            {/* ADD NOAHS PHOTO */}
                            <div className='images-wrapper'>
                                <div className='image-left'>
                                    <div>
                                        <img src={RabensPng} />
                                        <Header className='name' textAlign='center' as={"p"}>
                                            Alex Rabens
                                            <Header.Subheader
                                                className='position'
                                                textAlign="center"
                                                as={"p"}
                                            >
                                                Founder & CEO
                                            </Header.Subheader>
                                        </Header>
                                    </div>
                                </div>
                                <div className='image-right'>
                                    <div>
                                        <img src={NoahPng} />
                                        <Header className='name' textAlign='center' as={"p"}>
                                            Noah Crouse
                                            <Header.Subheader
                                                className='position'
                                                textAlign="center"
                                                as={"p"}
                                            >
                                                Associate Trader
                                            </Header.Subheader>
                                        </Header>
                                    </div>
                                </div>
                                <div className='image-left'>
                                    <div>
                                        <img src={JeffreyCrouse} />
                                        <Header className='name' textAlign='center' as={"p"}>
                                            Jeffrey Crouse
                                            <Header.Subheader
                                                className='position'
                                                textAlign="center"
                                                as={"p"}
                                            >
                                                Vice President of Trading
                                            </Header.Subheader>
                                        </Header>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>
                    <Grid
                        className="section-container"
                        id="request-demo"
                        stackable
                        width={16}
                    >
                        <Grid.Column
                            width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}
                        >
                            <Header
                                className="request-demo-header"
                                as={"h2"}
                                textAlign={wd.width <= 768 ? "center" : "left"}
                            >
                                Request a demo with Mickey Contractor Direct
                                <Header.Subheader
                                    className="request-demo-subheader"
                                    as={"p"}
                                >
                                    Request a demo with us to better understand how Mickey can be implemented at your company.
                                </Header.Subheader>
                            </Header>
                            <div className="request-wrapper">
                                <Input
                                    placeholder={"Enter Email..."}
                                    onChange={(e, d) => handleEmail(d?.value)}
                                />
                                <div className="orange-button-wrapper">
                                    <Button
                                        className="orange-button"
                                        href={`/request-demo/?email=${email}&origin=Contractor%20Direct%20Page&demoType=contractor-direct`}
                                    >
                                        Request Demo
                                    </Button>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            className="request-image-wrapper"
                            width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 7}
                        >
                            <img
                                src={RequestImg}
                            />
                        </Grid.Column>
                    </Grid>
                </>}
            </ContractorsDirectPageWrapper>

        </Layout >
    )
}

export default ContractorsDirectPage
