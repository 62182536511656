import React, { useState } from 'react'
import { Input, Grid, Header, Button, Tab } from "semantic-ui-react";
import Layout from '../../v2/Layout/layout2'
import { Link } from 'gatsby';
import styled from "styled-components";
import ContractorsDirect from "../../../images/contractors-direct.png"
import MickeyOS_01 from "../../../images/Mickey OS-01.png"
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import security from '../../../images/security.png'
import tracking from '../../../images/tracking.png'
import truck from '../../../images/truck.png'
import MaterialsXchange from '../../../images/mickey-materialsxchange.png'
import MickeyEnergy from '../../../images/Mickey-Energy.png'
import MickeyOS from '../../../images/mickey-os.png'
import ContractorDirectThumbnail from '../../../images/contractor-direct-thumbnail.png'
import RequestImg from '../../../images/requestimg.webp';
import '../../v2/PageComponents/Homepage.css'
import { media } from "../../../../mq"
import MickeyTabs from "../../v2/UtilityComponents/MickeyTabs";
import Exploration from '../../../images/Product Exploration-02.jpg';
import SEO from '../../../images/product-seo.jpg';
import DynamicStorefront from '../../../images/dynamic-storefront.jpg';
import OrderManagement from '../../../images/order-management.jpg';
import AutomatedFulfillment from '../../../images/automated-fulfillment.jpg';
import BuiltInAI from '../../../images/built-in-ai.jpg';
import Shipping from '../../../images/Product_Shipping-02.jpg';



const ProductpageWrapper = styled.div`
    padding: 3rem 0 3rem 0;
    margin: 0;
    ${media.smaller`padding: 5rem 3rem;`}
        .section-container{
            padding: 0;
            display: flex;
            flex-direction: column;
            margin-bottom: 50px ;
            ${media.smaller`margin-bottom: 100px; `}
            ${media.smallMedium`flex-direction: row;`}
            ${media.xl`justify-content: center;`}
            &#hero{
                justify-content: space-around;
                flex-direction: column;
                ${media.large`flex-direction: row;`}
                ${media.xl`justify-content: center;`}
                h1{
                    p{
                        font-size: 16px;
                        line-height: 1.8em;
                        margin-top: 2rem;
                        margin-bottom: 2rem;
                        font-family: "Brown Regular"
                    }
                }
                .request-wrapper{
                    display: flex;
                    height: 125px;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    ${media.small`height: 40px; flex-direction: row; align-items: flex-start;`}
                    ${media.smallMedium`justify-content: flex-start;`}
                    .ui.input{
                        margin-bottom: 1rem;
                        margin-right: 0;
                        ${media.small`margin-right: 2rem; margin-bottom: 0;`}
                    }
                }
                img{
                    width: 800px;
                    height: auto;
                }
            }
            &#why-use-mickey{
                justify-content: center;
                .row{
                    justify-content: space-between;
                    flex-direction: column;
                    max-width: 100%;
                    ${media.smallMedium`flex-direction: row;`}
                    ${media.xl`max-width: 87.5%;`}
                    .reason{
                        padding: 2rem;
                        img{
                            width: 72px;
                            height: auto;
                        }
                        h2{
                            font-size: 24px !important;
                        }
                        p{
                            line-height: 1.6em;
                            font-size: 16px;
                            font-family: "Brown Regular"
                        }

                    }
                }

            }
            &#features{
                justify-content: center;
                h2{
                    margin-bottom: 4rem;
                    h3{
                        font-family: "Brown Regular";
                        margin-top: 2rem;
                        margin-bottom: 1.5rem;
                        margin-right: 0;
                        margin-left: 0;
                        font-size: 20px;
                        ${media.smallSideways`margin-right: 19%; marginLeft: 19%;`}
                    }
                }
            }
            &#mickey-is-powering{
            justify-content: center;
            div.row{
                justify-content: space-between;
                flex-direction: column;
                max-width: 100%;
                ${media.xl`max-width: 87.5%;`}
                ${media.smallMedium`flex-direction: row;`}
                .mickey-product{
                    padding: 0 !important;
                    img{
                        width: 100%;
                        height: auto;
                        margin: 0;
                    }
                    .orange-heading{
                        font-size: 14px !important;
                        margin: 15px 0 15px 0 !important;
                    }
                    .product-link{
                        font-size: 24px !important;
                        margin: 0 !important;
                        p{
                            line-height: 1.6em;
                            font-size: 16px;
                            font-family: "Brown Regular";
                            margin-top: 15px;
                        }
                    }

                }
            }
        }
        &#request-demo{
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            ${media.large`flex-direction: row;`}
            ${media.xl`justify-content: center;`}
            .sixteen.wide.column{
                margin: 0 0 4rem 0 !important;
                ${media.small`margin: 0 !important;`}
            }

            .request-demo-header{
                width: 100%;
                ${media.large`width: 80%;`}
                .request-demo-subheader{
                    font-size: 16px;
                    line-height: 1.8em;
                    margin-bottom: 2rem;
                    margin-top: 2rem;
                }
            }
            .request-image-wrapper{
                text-align: center;
                margin-top: 4rem;
                ${media.large`text-align: right; margin-top: 0;`}
                    img{
                    width: 100%;
                    height: auto;
                }
            }
            .orange-button-wrapper{
                margin-bottom: 0;
                ${media.small`justify-content: flex-start;`}
            }
            .request-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                ${media.small`flex-direction: row;`}
                .ui.input{
                    border-radius: 50px;
                    border: 1px solid #6d6e70;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 2rem;
                    ${media.small`width: 300px; margin-right: 2rem; margin-bottom: 0;`}
                    input{
                        border-radius: 50px;
                        border: none;
                        width: inherit;
                    }
                }
            }
        }
    }

h2.ui.header{
    font-size: 36px !important;
    ${media.smaller`font-size: 48px !important;`}
}
h1.ui.header{
    font-size: 40px;
    ${media.small`font-size: 72px;`}
}
  
  
  .plywood_border {
    width: 433px;
    height: 4px;
    background: #f1592a;
    top: 47%;
  }
  .request-wrapper{
    display: flex;
    height: 40px;
    .ui.input{
      border-radius: 50px;
      border: 1px solid #6d6e70;
      width: 300px;
      margin-right: 2rem;
      input{
        border-radius: 50px;
        border: none;
        width: inherit;
      }
    }
  }
  .orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }
  .ui.attached.tabular.menu{
    border: none !important;
    padding-bottom: 1rem;
    flex-direction: column;
    ${media.smaller`flex-direction: row;`}

    a.item{
      border-bottom: 3px solid #f2f2f2 !important;
      font-family: "Brown Bold";

    }
    a.active.item{
      border-bottom: 3px solid #f1592a !important;
      font-family: "Brown Bold";
      margin-bottom: 0;
    }
    a:first-child{
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none;
      transition: border-bottom .4s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
    a:nth-of-type(2){
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none ;
      transition: border-bottom .2s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
    a:last-child{
      display: flex;
      justify-content: center;
      flex: 0 1 33%;
      border: none;
      transition: border-bottom .2s ease-in-out;
      font-family: "BrownLLSub-Regular";
    }
  }
  .ui.bottom.attached.segment.active.tab{
    border: none !important;
  }
  .indicators > li{
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .react-slideshow-wrapper .images-wrap{
    align-items: center;
  }
  a{
    color: black;
    transition: .2s ease-in-out;
    &:hover{
      color: #f1592a ;
    }
  }
  .orange-heading{
    font-size: 18px;
    color: #f1592a !important;
  }
  .orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }
`


const Products = () => {
    const wd = useWindowDimensions()
    const [email, setEmail] = useState("")

    const handleEmail = (inputEmail) => {
        setEmail(inputEmail)
    }

    const panes = [

        {
            menuItem: "Dynamic Storefront",
            content: function () {
                return (
                    <Tab.Pane style={{ paddingTop: "1rem" }}>
                        <Grid stackable width={16} style={{ justifyContent: "center", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 10}>
                                <img
                                    style={{
                                        maxHeight: wd?.width <= 1145 ? "auto" : "1000px",
                                        minWidth: "300px",
                                        width: wd?.width <= 1145 ? "100%" : "auto",
                                        marginTop: wd?.width <= 1145 ? "4rem" : ""
                                    }}
                                    src={DynamicStorefront}
                                />
                            </Grid.Column >
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "20px", marginTop: "10rem", fontFamily: "BrownLLSub-Regular", width: wd?.width <= 1145 ? "100%" : "70%" }}>
                                        Our premade storefront allows your business to upload product information and prices easily.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >
                )
            }
        },
        {
            menuItem: "Order Management",
            content: function (props) {
                return (
                    < Tab.Pane style={{ paddingTop: "1rem" }}>
                        <Grid stackable width={16} style={{ justifyContent: "center", flexDirection: wd.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ maxHeight: "1000px", minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={OrderManagement} />
                            </Grid.Column>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "20px", marginTop: "10rem", fontFamily: "BrownLLSub-Regular", width: wd?.width <= 1145 ? "100%" : "70%" }}>
                                        Send, receive, and manage all of your customer’s orders in one place. Search past and current orders, manage inventories, and see complete sales reports from your dashboard.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >
                )
            }
        },
        {
            menuItem: "Automated Fulfillment",
            content: function () {
                return (

                    < Tab.Pane style={{ paddingTop: "1rem" }}>
                        <Grid stackable width={16} style={{ justifyContent: "center", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ maxHeight: "1000px", minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={AutomatedFulfillment} />
                            </Grid.Column>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "20px", marginTop: "10rem", fontFamily: "BrownLLSub-Regular", width: wd?.width <= 1145 ? "100%" : "70%" }}>
                                        Our technology enables a streamlined order - which includes pickup and delivery services from our logistics partners. Once an order is placed, your platform will automatically handle the logistics and you and your customer can watch the delivery progress right from the dashboard.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >

                )
            }
        },
        {
            menuItem: "Built-in AI",
            content: function () {
                return (

                    < Tab.Pane style={{ paddingTop: "1rem" }}>
                        <Grid stackable width={16} style={{ justifyContent: "center", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ maxHeight: "1000px", minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={BuiltInAI} />
                            </Grid.Column>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "20px", marginTop: "10rem", fontFamily: "BrownLLSub-Regular", width: wd?.width <= 1145 ? "100%" : "70%" }}>
                                        Our Built-in AI features allows for businesses with many products not to have to write their own product descriptions, the AI will do it automatically.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >

                )
            }
        },
        {
            menuItem: "Integrated SEO",
            content: function () {
                return (

                    < Tab.Pane style={{ paddingTop: "1rem" }}>
                        <Grid stackable width={16} style={{ justifyContent: "center", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ maxHeight: "1000px", minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={SEO} />
                            </Grid.Column>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "20px", marginTop: "10rem", fontFamily: "BrownLLSub-Regular", width: wd?.width <= 1145 ? "100%" : "70%" }}>
                                        We leverage SEO technology that can help create the content that makes your product pages, product descriptions, and more become searchable on the web.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >

                )
            }
        }

    ]


    return (
        <Layout>
            <ProductpageWrapper>
                {
                    wd && wd?.width && <>
                        <Grid
                            className="section-container"
                            id="hero"
                            stackable
                            width={16}
                        >
                            <Grid.Column width={(wd.width >= 1600 && 6) || (wd.width <= 1200 ? 16 : 6)}>
                                <Header as={"h1"} textAlign={wd.width <= 991 ? "center" : "left"}>
                                    Mickey for <br />
                                    Enterprise <br />
                                    <Header.Subheader as={"p"}>
                                        Mickey is the go-to SaaS provider for wholesale and commodity enterprises. America’s suppliers looking to increase their online sales presence, while adding a new revenue stream, can leverage Mickey’s technology to bring their online sales platform to life.
                                    </Header.Subheader>
                                </Header>
                                <div className="request-wrapper">
                                    <Input
                                        placeholder={"Enter Email..."}
                                        onChange={(e, d) => handleEmail(d?.value)}
                                    />
                                    <Button
                                        className="orange-button"
                                        href={`/request-demo/?email=${email}&origin=Products%20Page`}
                                    >
                                        Request Demo
                                    </Button>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 8}
                                textAlign={wd.width <= 1200 ? "center" : "right"}
                            >
                                <img src={MickeyOS_01} />
                            </Grid.Column>
                        </Grid>
                        <Grid className="section-container" id="why-use-mickey" stackable width={wd.width >= 1600 ? 14 : 16}>
                            <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="left">
                                <Header as={"h2"}>
                                    Why use Mickey for your business
                                </Header>
                            </Grid.Column>
                            <Grid.Row >
                                <Grid.Column className='reason' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={security} alt='' />
                                    <Header as={"h2"}>
                                        Order Management
                                    </Header>
                                    <p>
                                        Mickey OS provides all of the back-end order management you need to keep your business running smoothly. With up-to-the-minute inventory reports, automated billing and invoicing, advanced payment options (like ACH, BNPL, etc.) and easy-to-print order forms and receipts, we can make your online ordering process seamless and completely transparent.              </p>
                                </Grid.Column>
                                <Grid.Column className='reason' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={truck} alt='' />
                                    <Header as={"h2"}>
                                        Online Custom Marketplace
                                    </Header>
                                    <p>
                                        Mickey customizes its Mickey OS to fit your products and industry. Our technology can provide your users with Instant quotes, a complete end-to-end transaction, streamlined fulfillment, and more.            </p>
                                </Grid.Column>
                                <Grid.Column className='reason' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={tracking} alt='' />
                                    <Header as={"h2"} >
                                        Automated Fulfillment
                                    </Header>
                                    <p>
                                        Mickey has established partnerships with the country’s leading logistics companies to provide complete fulfillment including automated pick-up and delivery with real-time pricing and live tracking. With Mickey OS, buyers can schedule a delivery and a destination and track their order in real time.            </p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid
                            className="section-container"
                            id="features"
                            stackable
                            width={wd.width >= 1600 ? 14 : 16}
                        >
                            <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                                <Header as={"h2"}>
                                    Features on our Software
                                    <Header.Subheader as={"h3"}>
                                        We help modernize the way you sell and manage your wholesale and commodity products online while making it easier and more transparent for your customers to place orders.              </Header.Subheader>
                                </Header>
                                <div className="section-container">
                                    <MickeyTabs data={panes} />
                                </div>
                            </Grid.Column>
                        </Grid>
                        <Grid className="section-container" id="mickey-is-powering" width={wd.width >= 1600 ? 14 : 16}>
                            <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                                <Header as={"h2"}>
                                    Mickey is Powering
                                </Header>
                            </Grid.Column>
                            <Grid.Row>
                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <a href="/about">
                                        <img src={MickeyOS} alt='' />
                                    </a>
                                    <Header as={"p"} className="orange-heading">Mickey OS</Header>
                                    <Header as={"h2"} className="product-link">
                                        <Link to="/about">
                                            Mickey | Mickey OS
                                        </Link>
                                        <Header.Subheader as={"p"}>
                                            Automated B2B Marketplace for wholesale products
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>

                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <a href="/contractor-direct">
                                        <img src={ContractorDirectThumbnail} alt='' />

                                    </a>
                                    <Header as={"p"} className="orange-heading">Contractor Direct</Header>
                                    <Header as={"h2"} className="product-link">
                                        <Link to="/contractor-direct">
                                            Mickey | Contractor Direct
                                        </Link>
                                        <Header.Subheader as={"p"}>
                                            An integrated platform allowing traders to build material lists, send them out as RFQ’s, and book logistics in one place.
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <a href="/https://marketplace.mxlbr.com/">

                                        <img src={MaterialsXchange} alt='' />
                                    </a>
                                    <Header as={"p"} className="orange-heading">Mickey OS</Header>
                                    <Header as={"h2"} className="product-link" >
                                        <a href="https://marketplace.mxlbr.com/">
                                            MaterialsXchange
                                        </a>
                                        <Header.Subheader as={"p"}>
                                            The Digital marketplace for Lumber and Panels
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>

                            </Grid.Row>
                            <Grid.Row>

                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <a href="/mickey-energy">

                                        <img src={MickeyEnergy} alt='' />
                                    </a>
                                    <Header as={"p"} className="orange-heading">Private Software</Header>
                                    <Header as={"h2"} className="product-link">
                                        <Link to="/mickey-energy">
                                            Mickey | Energy
                                        </Link>
                                        <Header.Subheader as={"p"}>
                                            Trading Technology for Liquified Natural Gas
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                            {/* <Grid.Row>
                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <img src={MickeyOS} alt='' />
                                    <Header as={"p"} className="orange-heading">Contractor Direct</Header>
                                    <Header as={"h2"} className="product-link">
                                        <Link to="/contractor-direct">
                                            Contractor Direct
                                        </Link>
                                        <Header.Subheader as={"p"}>
                                            An integrated platform allowing traders to build material lists, send them out as RFQ’s, and book logistics in one place.
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>

                            </Grid.Row> */}
                        </Grid>
                        <Grid
                            className="section-container"
                            id="request-demo"
                            stackable
                            width={16}
                        >
                            <Grid.Column
                                width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}
                            >
                                <Header
                                    className="request-demo-header"
                                    as={"h2"}
                                    textAlign={wd.width <= 768 ? "center" : "left"}
                                >
                                    Request a demo with Mickey today.
                                    <Header.Subheader
                                        className="request-demo-subheader"
                                        as={"p"}
                                    >
                                        Request a demo with us to better understand how Mickey can be implemented at your company.
                                    </Header.Subheader>
                                </Header>
                                <div className="request-wrapper">
                                    <Input
                                        placeholder={"Enter Email..."}
                                        onChange={(e, d) => handleEmail(d?.value)}
                                    />
                                    <div className="orange-button-wrapper">
                                        <Button
                                            className="orange-button"
                                            href={`/request-demo/?email=${email}&origin=Products%20Page`}
                                        >
                                            Request Demo
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                className="request-image-wrapper"
                                width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 7}
                            >
                                <img
                                    src={RequestImg}
                                />
                            </Grid.Column>
                        </Grid>
                    </>
                }
            </ProductpageWrapper>
        </Layout>
    )
}

export default Products
