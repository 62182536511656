import React, { useState, useEffect } from 'react'
import Layout from '../../v2/Layout/layout2'
import styled from 'styled-components'
import { StaticImage } from 'gatsby-plugin-image'
import { Grid, Header, Button, Tab, Input, Divider } from "semantic-ui-react"
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import MickeyTabs from '../../v2/UtilityComponents/MickeyTabs'
import SmallToMedium from "../../../images/small-to-medium.jpg"
import AutomatedTrading from "../../../images/automated-trading.jpg"
import PriceTransparency from "../../../images/price-transparency.jpg"
import RabensPng from "../../../images/Rabens.png"
import RequestImg from "../../../images/requestimg.webp"
import { useStaticQuery, graphql } from 'gatsby'
import PressRow from '../../v2/PressPage/PressRow'
import NewsRow from '../../v2/NewsPage/NewsRow'
import EnergyBackground from "../../../videos/energy.webmhd.webm"
import JohnPlaceholder from "../../../images/john-placeholder.png"
import SemiTruck from "../../../images/industries-page-background.jpg"
import { media } from "../../../../mq"
const EnergyWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
  #video-wrapper {
        height: 100vh;
        width: inherit;
        overflow: inherit;
        position: relative;
        .gatsby-image-wrapper{
           height: calc(100vh - 65px);
        }
        .overlay-text{
            top: 15vh;
            position: absolute;
            height: 100%;
            align-items: center;
            flex-wrap: nowrap;
            color: white;
            width: 100%;
        }
        .background-video {
            min-height: 100%;
            min-width: 100%;
        }
        .ui.grid{
            margin: 0 !important;
        }
    }
  .section-container{
        padding: 3rem 0 3rem 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        ${media.smallMedium`flex-direction: row; margin-bottom: 100px;`}
        ${media.smaller`padding: 3rem 5rem;`}
        ${media.xl`justify-content: center;`}
        &#hero{
            h1{
                width: 100%;
                margin: 2rem 0 2rem 0;
                text-align: center;
                ${media.small`text-align: left;`}
            }

        }
        &#leadership{
        flex-direction: column;
        min-height: 500px;
        align-items: center;
        justify-content: center;
        ${media.smallMedium`flex-direction: row;`}
        .leadership-left{
            margin-bottom: 2rem;
            min-height: inherit;
            ${media.smallMedium`margin-bottom: 0;`}
            .orange-button-wrapper{
                justify-content: center;
                ${media.small`justify-content: flex-start;`}
            }
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
                p{
                    font-size: 16px;
                    line-height: 2em;
                    margin-top: 2rem;
                    font-family: "Brown Regular";
                }
            }
        }
        .leadership-right{
            min-height: inherit;
            .images-wrapper{
                display: flex;
                min-height: inherit;
                flex-direction: column;
                margin-top: 3rem;
                ${media.small`flex-direction: row; margin-top: 0; justify-content: flex-end;`}
                .image-left{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex: 0 1 50%;
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 3rem;
                        ${media.small`margin-bottom: 0;`}
                        img{
                            width: 300px;
                            height: auto;
                        }
                        .name{
                            margin-top: 1rem;
                            font-size: 18px;
                            .position{
                                font-family: "Brown Regular";
                                margin-top: .5rem;
                            }
                        }
                    }
                    
                }
                .image-right{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    flex: 0 1 50%;
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 3rem;
                        ${media.small`margin-bottom: 0;`}
                        img{
                            width: 300px;
                            height: auto;
                        }
                        .name{
                            margin-top: 1rem;
                            font-size: 18px;
                            .position{
                                font-family: "Brown Regular";
                                margin-top: .5rem;
                            }
                        }
                    }
                    
                }
            }

        }
    }
    &#block-quote{
        background-color: rgb(242, 242, 242);
        min-height: 50vh;
        margin: 0;
        display: flex;
        flex-direction: column;
        padding: 4rem 1rem 4rem 1rem;
        ${media.small`padding: 4rem;`}
        h2{
            color: #f1592a;
            width: 100%;
            text-align: center;
            ${media.small`text-align: left;`} 
        }
        .block-quote-container{
            margin-top: 4rem;
            display: flex;
            align-self: center;
            ${media.small`width: 70%;`}
            .quote{
                text-align: center;
                font-family: "Merriweather-Light" !important;
                line-height: 2em;
                font-size: 20px;
                ${media.small`text-align: left; font-size: 1.07142857rem; font-size: 25px; `}
                p{
                    text-align: center;
                    margin-top: 2rem;
                    font-family: "Brown Bold";
                    span{
                        font-family: "Brown Light";

                    }
                }
            }
        }
    }
    &#energy-press{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        padding: 1rem 1rem;
        h1{
            margin-bottom: 2rem;
        }
        button{
            width: 250px;
        }
    }
    &#energy-news{
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;
        padding: 1rem 1rem;
        h1{
            margin-bottom: 2rem;
        }
        button{
            width: 250px;
        }
    }
    &#request-demo{
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            ${media.large`flex-direction: row;`}
            ${media.xl`justify-content: center;`}
            .sixteen.wide.column{
                margin: 0 0 4rem 0 !important;
                ${media.small`margin: 0 !important;`}
            }

            .request-demo-header{
                width: 100%;
                ${media.large`width: 80%;`}
                .request-demo-subheader{
                    font-size: 16px;
                    line-height: 1.8em;
                    margin-bottom: 2rem;
                    margin-top: 2rem;
                }
            }
            .request-image-wrapper{
                text-align: center;
                margin-top: 4rem;
                ${media.large`text-align: right; margin-top: 0;`}
                    img{
                    width: 100%;
                    height: auto;
                }
            }
            .orange-button-wrapper{
                margin-bottom: 0;
                ${media.small`justify-content: flex-start;`}
            }
            .request-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                ${media.small`flex-direction: row;`}
                .ui.input{
                    border-radius: 50px;
                    border: 1px solid #6d6e70;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 2rem;
                    ${media.small`width: 300px; margin-right: 2rem; margin-bottom: 0;`}
                    input{
                        border-radius: 50px;
                        border: none;
                        width: inherit;
                    }
                }
            }
    }
}

    h1,h2{
    font-size: 36px !important;
    ${media.smaller`font-size: 48px !important;`}
}

.orange-heading{
    font-size: 18px;
    color: #f1592a !important;
  }
  .orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }
`

const Energy = () => {
    const data = useStaticQuery(graphql`
    query EnergyQuery {
        allWpPressRelease {
            edges {
            node {
                slug
                title
                pressReleases {
                month
                day
                year
                articleLink
                headline
                pressReleaseContent
                pressReleaseSubheader
                energy
                image {
                    localFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: WEBP, height: 100)
                    }
                    }
                }
                }
            }
            }
        }
        allWpNewsArticle {
            edges {
            node {
                title
                newsArticles {
                month
                day
                year
                articleLink
                headline
                newsArticleSnippet
                energy
                image {
                    localFile {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, formats: WEBP, width: 250)
                    }
                    }
                }
                }
            }
            }
        }
    }

`)
    const [email, setEmail] = useState("")
    const wd = useWindowDimensions()
    const [sortedEnergyPressReleases, setSortedEnergyPressReleases] = useState([])
    const [sortedEnergyNewsArticles, setSortedEnergyNewsArticles] = useState([])


    const energyPressReleases = data?.allWpPressRelease?.edges
    const energyNewsArticles = data?.allWpNewsArticle?.edges

    useEffect(() => {
        let releasesWithDates = []
        let newsWithDates = []
        energyPressReleases.forEach(release => {
            releasesWithDates.push(
                {
                    date: `${release.node.pressReleases.year}-${release.node.pressReleases.month}-${release.node.pressReleases.day}`,
                    month: release.node.pressReleases.month,
                    day: release.node.pressReleases.day,
                    year: release.node.pressReleases.year,
                    articleLink: release.node.pressReleases.articleLink,
                    pressReleaseContent: release.node.pressReleases.pressReleaseContent,
                    image: release.node.pressReleases.image.localFile,
                    slug: release.node.slug,
                    title: release.node.title,
                    headline: release.node.pressReleases.headline,
                    subheader: release.node.pressReleases.pressReleaseSubheader,
                    energy: release.node.pressReleases.energy
                }
            )
        })

        energyNewsArticles.forEach(news => {
            newsWithDates.push({
                date: `${news.node.newsArticles.year}-${news.node.newsArticles.month}-${news.node.newsArticles.day}`,
                month: news.node.newsArticles.month,
                day: news.node.newsArticles.day,
                year: news.node.newsArticles.year,
                articleLink: news.node.newsArticles.articleLink,
                pressnewsContent: news.node.newsArticles.pressnewsContent,
                image: news.node.newsArticles.image.localFile,
                slug: news.node.slug,
                title: news.node.title,
                headline: news.node.newsArticles.headline,
                snippet: news.node.newsArticles.newsArticleSnippet,
                energy: news.node.newsArticles.energy
            })
        })

        const sortedReleases = releasesWithDates.sort((a, b) => new Date(b.date) - new Date(a.date))
        const energyReleases = sortedReleases.filter((release) => { return release.energy === true })
        setSortedEnergyPressReleases(energyReleases)

        const sortedNews = newsWithDates.sort((a, b) => new Date(b.date) - new Date(a.date))
        const energyNews = sortedNews.filter((news) => { return news.energy === true })
        setSortedEnergyNewsArticles(energyNews)

    }, [])

    const handleEmail = (inputEmail) => {
        setEmail(inputEmail)
    }

    const panes = [
        {
            menuItem: "Small-To Medium-Sized Producers",
            content: function () {
                return (
                    <Tab.Pane>
                        <Grid stackable width={16} style={{ justifyContent: "center", alignItems: "center", paddingBottom: "4rem", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    Volume Flexibility
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "16px", marginTop: "2rem", fontFamily: "BrownLLSub-Regular", }}>
                                        Prior to Mickey, only a handful of dedicated energy marketers were helping to get natural gas products distributed across the U.S. -- now, we’re able to work closely with smaller suppliers who relied on larger, more expensive firms to handle their transactions.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                            <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }} width={wd?.width <= 1145 ? 16 : 10}>
                                <img
                                    style={{
                                        minWidth: "300px",
                                        width: wd?.width <= 1145 ? "100%" : "auto",
                                        marginTop: wd?.width <= 1145 ? "4rem" : ""
                                    }}
                                    src={SmallToMedium}
                                />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >
                )
            }
        },
        {
            menuItem: "Price Transparency",
            content: function () {
                return (

                    < Tab.Pane>
                        <Grid stackable width={16} style={{ justifyContent: "center", alignItems: "center", paddingBottom: "4rem", flexDirection: wd?.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    Have a clear view of the market.
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "16px", marginTop: "2rem", fontFamily: "BrownLLSub-Regular", }}>
                                        Mickey’s energy division allows small and medium-sized producers and end users the kind of price transparency and volume flexibility that historically has only been available to the largest companies in the space.
                                    </Header.Subheader>
                                </Header>

                            </Grid.Column>
                            <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }} width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={PriceTransparency} />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >

                )
            }
        },
        {
            menuItem: "Automated Trading",
            content: function (props) {
                return (
                    < Tab.Pane>
                        <Grid stackable width={16} style={{ justifyContent: "center", alignItems: "center", paddingBottom: "4rem", flexDirection: wd.width <= 1145 ? "column" : "row" }}>
                            <Grid.Column width={wd?.width <= 1145 ? 16 : 6}>
                                <Header as={"h3"} style={{ fontSize: "36px", textAlign: wd?.width <= 1145 ? "center" : "left" }}>
                                    Trade easily through automated tasks and alerts.
                                    <Header.Subheader style={{ lineHeight: "1.8em", fontSize: "16px", marginTop: "2rem", fontFamily: "BrownLLSub-Regular", }}>
                                        Mickey has built a proprietary platform that allows our natural gas trading desk to have access to real-time natural gas levels, disruptions in service, planned outages, and much more.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }} width={wd?.width <= 1145 ? 16 : 10}>
                                <img style={{ minWidth: "300px", width: "auto", marginTop: wd?.width <= 1145 ? "4rem" : "" }} src={AutomatedTrading} />
                            </Grid.Column>
                        </Grid>
                    </Tab.Pane >
                )
            }
        }

    ]

    return (
        <Layout>
            <EnergyWrapper>
                {wd && wd?.width && <>
                    <div id="video-wrapper">

                        {
                            wd.width <= 540 &&
                            <div className='gatsby-image-wrapper'>
                                <img src={SemiTruck} />
                            </div>

                        }
                        {
                            wd.width > 540 &&
                            <video
                                className='background-video'
                                preload
                                loop
                                muted
                                autoPlay
                            >
                                <source src={EnergyBackground} type="video/webm" />
                            </video>
                        }
                        <Grid
                            className='overlay-text'
                            style={{
                                flexDirection: "column",
                                top: wd.width <= 950 && "10vh"
                            }}
                            width={16}
                        >
                            <Grid.Column width={(wd.width <= 950 && wd.width >= 768 && 10) || (wd.width < 768 && 13) || wd.width > 950 && 6} style={{ marginBottom: "1.5rem" }}>
                                <Header textAlign='center' className='digital' as={"h1"} style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)', fontSize: wd.width <= 540 ? "40px" : "65px", color: "inherit" }}>
                                    Mickey Energy for the LNG Market
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={(wd.width <= 950 && wd.width >= 768 && 10) || (wd.width < 768 && 13) || wd.width > 950 && 8} style={{ marginBottom: "1.5rem" }}>
                                <Header textAlign='center' className='mission' as={"p"} style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)', fontSize: "20px", color: "inherit" }}>
                                    In 2021, Mickey launched a natural gas division. Mickey Energy’s platform enables domestic natural gas buyers to source supplies from small-to-medium-sized producers in the US and manages all aspects of the trade including, hedging, logistics, distribution, and risk management.
                                </Header>
                            </Grid.Column>
                            <Grid.Column textAlign='center' width={8} style={{ display: "flex", justifyContent: "center", marginBottom: "5rem" }}>
                                <Button href="/about" className='orange-button'>
                                    Learn More
                                </Button>
                            </Grid.Column>
                        </Grid>
                    </div>
                    <Grid className='section-container' id="hero">
                        <Header as={"h1"}>
                            Natural Gas Trading Technology
                        </Header>
                        <MickeyTabs data={panes} />
                    </Grid>
                    <Grid className='section-container' id="block-quote">
                        <Header as={"h2"}>
                            Cleaner energy for the future.
                        </Header>
                        <div className='block-quote-container'>
                            <Header as={"h4"} className="quote">
                                “The U.S. is striving to reduce its reliance on fossil fuels through cleaner, more natural solutions. With more electric vehicles on the roads today, and other non-fossil fuel innovations, these products will still need a source of power and cleaner, natural gas will be the country’s best option going forward.”
                                <Header.Subheader as={"p"}>
                                    Alex Rabens <span>CEO of Mickey</span>
                                </Header.Subheader>
                            </Header>
                        </div>
                    </Grid>
                    <Grid
                        stackable
                        width={wd.width >= 1600 ? 14 : 16}
                        className="section-container"
                        id="leadership"
                        style={{ marginTop: "4rem" }}
                    >

                        <Grid.Column className='leadership-left' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                            <Header as={"h2"}>
                                Strong Leadership.<br />
                                Discerning Vision.
                                <Header.Subheader as={"p"}>
                                    Mickey was founded by Alex Rabens who leads the company as its CEO.
                                </Header.Subheader>
                            </Header>
                            <div className='orange-button-wrapper'>
                                <Button
                                    className="orange-button"
                                    href="/team"
                                    style={{
                                        height: "40px"
                                    }}
                                >
                                    Meet Our Entire Team
                                </Button>
                            </div>

                        </Grid.Column>
                        <Grid.Column className='leadership-right' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                            <div className='images-wrapper'>
                                <div className='image-left'>
                                    <div>
                                        <img src={RabensPng} />
                                        <Header className='name' textAlign='center' as={"p"}>
                                            Alex Rabens
                                            <Header.Subheader
                                                className='position'
                                                textAlign="center"
                                                as={"p"}
                                            >
                                                Founder & CEO
                                            </Header.Subheader>
                                        </Header>
                                    </div>
                                </div>
                            </div>
                        </Grid.Column>
                    </Grid>

                    <Grid className="section-container" id="energy-press">
                        <Grid.Column width={wd.width <= 768 ? 16 : 14}>
                            <Header as={"h1"}>Press</Header>
                            <Divider />
                            <div>
                                {
                                    sortedEnergyPressReleases?.slice(0, 4).map(release => {
                                        const day = release?.day
                                        const month = release?.month
                                        const year = release?.year
                                        const date = release?.date
                                        const image = release?.image
                                        const title = release?.title
                                        const link = release?.articleLink
                                        const headline = release?.headline
                                        const slug = release?.slug
                                        const pressReleaseContent = release?.pressReleaseContent
                                        const subheader = release?.subheader
                                        return (
                                            <>
                                                <PressRow
                                                    day={day}
                                                    month={month}
                                                    year={year}
                                                    date={date}
                                                    image={image}
                                                    alt={title}
                                                    href={link}
                                                    headline={headline}
                                                    slug={slug}
                                                    pressReleaseContent={pressReleaseContent}
                                                    subheader={subheader}
                                                />
                                                <Divider />
                                            </>
                                        )
                                    })
                                }
                            </div>
                            {
                                sortedEnergyPressReleases.length > 4 &&
                                <Button className='orange-button' href="/press">See All Press Releases</Button>
                            }
                        </Grid.Column>
                    </Grid>
                    <Grid className="section-container" id="energy-news">
                        <Grid.Column width={wd.width <= 768 ? 16 : 14}>
                            <Header
                                className="press-releases-header"
                                as={"h1"}
                            >
                                News
                            </Header>
                            <Divider />
                            <div>
                                {sortedEnergyNewsArticles?.slice(0, 4).map(news => {
                                    const day = news?.day
                                    const month = news?.month
                                    const year = news?.year
                                    const date = news?.date
                                    const image = news?.image
                                    const title = news?.title
                                    const link = news?.articleLink
                                    const headline = news?.headline
                                    const snippet = news?.snippet
                                    return (
                                        <>
                                            <NewsRow
                                                day={day}
                                                month={month}
                                                year={year}
                                                date={date}
                                                image={image}
                                                alt={title}
                                                href={link}
                                                headline={headline}
                                                snippet={snippet}
                                            />
                                            <Divider />
                                        </>
                                    )
                                })}
                            </div>
                            {
                                sortedEnergyNewsArticles.length > 4 &&

                                <Button className='orange-button' href="/news">See All News Articles</Button>
                            }
                        </Grid.Column>
                    </Grid>


                    <Grid
                        className="section-container"
                        id="request-demo"
                        stackable
                        width={16}
                    >
                        <Grid.Column
                            width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}
                        >
                            <Header
                                className="request-demo-header"
                                as={"h2"}
                                textAlign={wd.width <= 768 ? "center" : "left"}
                            >
                                Request a demo with Mickey today.
                                <Header.Subheader
                                    className="request-demo-subheader"
                                    as={"p"}
                                >
                                    Request a demo with us to better understand how Mickey can be implemented at your company.
                                </Header.Subheader>
                            </Header>
                            <div className="request-wrapper">
                                <Input
                                    placeholder={"Enter Email..."}
                                    onChange={(e, d) => handleEmail(d?.value)}
                                />
                                <div className="orange-button-wrapper">
                                    <Button
                                        className="orange-button"
                                        href={`/request-demo/?email=${email}&origin=Energy%20Page`}
                                    >
                                        Request Demo
                                    </Button>
                                </div>
                            </div>
                        </Grid.Column>
                        <Grid.Column
                            className="request-image-wrapper"
                            width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 7}
                        >
                            <img
                                src={RequestImg}
                            />
                        </Grid.Column>
                    </Grid>
                </>
                }
            </EnergyWrapper>
        </Layout>
    )
}

export default Energy