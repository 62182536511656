import React, { useState, useEffect } from "react"
import Layout from "../../v2/Layout/layout2"
import PhoneInput from "react-phone-input-2"
import {
    Segment,
    Header,
    Grid,
    Message,
    Button,
    Form,
    List,
    Icon

} from "semantic-ui-react"
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import styled from "styled-components"
import { Circle, TextAa } from "phosphor-react"
import axios from "axios"
import { zipCodeValidator, emailValidator, phoneNumberValidator, countryValidator } from "../../../utils/utils"
import { validate } from "react-email-validator"
import lookup from "country-code-lookup"
import queryString from 'query-string';


const AllRequestWrapper = styled.div`
width: 100vw;
height: fit-content;
display: flex;
justify-content: center;
padding: 5rem 2rem 5rem 2rem;
.ui.list {
    font-size: 18px;
  }
  .item {
    line-height: 1.2em !important;
    margin-bottom: 1rem !important;
  }
  .form-wrapper {
    padding: 3rem;
    border-radius: 14px !important;
    box-shadow: 0 1px 1px hsl(0deg 0% 0% / 0.075),
      0 2px 2px hsl(0deg 0% 0% / 0.075), 0 4px 4px hsl(0deg 0% 0% / 0.075),
      0 8px 8px hsl(0deg 0% 0% / 0.075), 0 16px 16px hsl(0deg 0% 0% / 0.075);
  }
  .submit-button{
    width: 100%;
    color: white ;
    border-radius: 500px ;
    background-color: #f1592a;
    transition: .1s ease-in-out;
    border: 1px solid #f1592a;      
    &:hover{
      background-color: white;
      color: #f1592a;
    }
}
.ui.segment{
box-shadow: rgb(0 0 0 / 8%) 3px 1px 18px 4px;

  border: none;

}
.form{
    .special-label{
      text-transform: none !important;
    color: black !important;
    font-size: 12px !important;
    margin-bottom: 0.28571429rem !important;
    &::after{
      margin: -0.2em 0 0 0.2em;
    content: '*';
    color: #db2828;
    }
    }
    .react-tel-input{
      width: 100%;
      flex: 0 1 50%;
    }
    label{
        text-transform: none !important;
        color: black !important;
        font-size: 12px !important;
    }
    .field{
        label{
            text-transform: none !important;
            color: black !important;
            font-size: 12px !important;
            font-family: "BrownLLSub-Regular" !important;
            font-weight: 400 !important;
        }
        .divider.default.text{
            font-family: "BrownLLSub-Regular" !important; 
        }
    }
    .homepage-dropdown{
      .ui.fluid.search.dropdown{
        height: 38.36px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: space-between !important;
    padding: 0.67857143em 1em !important;
    input{
      border: 1px solid rgba(34,36,38,.15) !important;
    }
      }
 
    }
    .discovery-dropdown{
        div{
            .visible.menu.transition{
                .item{
                    font-size: 1em !important;
                }
            }
        }
    }
}
`

const RequestDemo = () => {
    const location = typeof window !== 'undefined' ? window?.location.search : ""
    const wd = useWindowDimensions()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState(queryString.parse(location).email ? queryString.parse(location).email : "")
    const [discovery, setDiscovery] = useState(queryString.parse(location).discovery ? queryString.parse(location).discovery : "")
    const [origin, setOrigin] = useState(queryString.parse(location).origin ? queryString.parse(location).origin : "")
    const [demoType, setDemoType] = useState(queryString.parse(location).demoType ? queryString.parse(location).demoType : "")
    const [businessName, setBusinessName] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [website, setWebsite] = useState("")
    // const [address1, setAddress1] = useState("")
    // const [address2, setAddress2] = useState("")
    // const [city, setCity] = useState("")
    // const [state, setState] = useState("")
    // const [zip, setZip] = useState("")
    // const [country, setCountry] = useState("")
    const [industry, setIndustry] = useState("")
    const [discoveryOther, setDiscoveryOther] = useState("")
    const [demoTypeOther, setDemoTypeOther] = useState("")
    const [showSuccessMessage, setShowSuccessMessage] = useState(false)
    const [error, setError] = useState(null)

    const handleBusinessName = (inputBusinessName) => {
        setBusinessName(inputBusinessName)
    }
    // const handleAddress1 = (inputAddress) => {
    //     setAddress1(inputAddress)
    // }
    // const handleAddress2 = (inputAddress) => {
    //     setAddress2(inputAddress)
    // }
    const handleWebsite = (inputWebsite) => {
        setWebsite(inputWebsite)
    }
    const handleIndustry = (inputIndustry) => {
        setIndustry(inputIndustry)
    }
    const handlePhoneNumber = (inputPhoneNumber) => {
        const errorMessage = phoneNumberValidator(inputPhoneNumber)
        if (!errorMessage) {
            setPhoneNumber(inputPhoneNumber)
            setError(null)
        } else {
            setError(errorMessage)
        }

    }
    const handleEmail = (inputEmail) => {
        const errorMessage = emailValidator(inputEmail)
        setEmail(inputEmail)
        if (!errorMessage) {
            setError(null)
        } else {
            setError(errorMessage)
        }
    }
    const handleFirstName = (inputFirstName) => {
        setFirstName(inputFirstName)
    }
    const handleLastName = (inputLastName) => {
        setLastName(inputLastName)
    }
    const handleDiscovery = (discoveryInput) => {
        setDiscovery(discoveryInput)
    }
    const handleDiscoveryOther = (otherInput) => {
        setDiscoveryOther(otherInput)
    }
    const handleDemoType = (demoTypeInput) => {
        setDemoType(demoTypeInput)
    }
    const handleDemoTypeOther = (otherInput) => {
        setDemoTypeOther(otherInput)
    }
    // const handleCity = (inputCity) => {
    //     setCity(inputCity)
    // }
    // const handleState = (inputState) => {
    //     setState(inputState)
    // }
    // const handleCountry = (inputCountry) => {
    //     setCountry(inputCountry)
    // }
    // const handleZip = (inputZip) => {

    //     const errorMessage = zipCodeValidator(inputZip)
    //     if (!errorMessage) {
    //         setZip(inputZip)
    //         setError(null)
    //     } else (
    //         setError(errorMessage)
    //     )
    // }

    const demoTypeOptions = [
        {
            key: 'Contractor Direct',
            text: 'Contractor Direct',
            value: 'contractor-direct',
        },
        {
            key: 'Mickey OS',
            text: 'Mickey OS',
            value: 'mickey-os',
        },
        {
            key: 'MaterialsXchange',
            text: 'MaterialsXchange',
            value: 'materialsxchange',
        },
        {
            key: 'Other',
            text: 'Other',
            value: 'other',
        },
    ]


    const discoveryOptions = [
        {
            key: 'Facebook',
            text: 'Facebook',
            value: 'facebook',
        },
        {
            key: 'LinkedIn',
            text: 'LinkedIn',
            value: 'linkedin',
        },
        {
            key: 'Instagram',
            text: 'Instagram',
            value: 'instagram',
        },
        {
            key: 'Twitter',
            text: 'Twitter',
            value: 'twitter',
        },
        {
            key: 'E-mail',
            text: 'E-mail',
            value: 'email',
        },
        {
            key: 'Youtube',
            text: 'Youtube',
            value: 'youtube',
        },
        {
            key: 'Press',
            text: 'Press',
            value: 'press',
        },
        {
            key: 'Word of Mouth',
            text: 'Word of Mouth',
            value: 'word-of-mouth',
        },
        {
            key: 'Search Engine',
            text: 'Search Engine',
            value: 'search-engine',
        },
        {
            key: 'Other',
            text: 'Other',
            value: 'other',
        },
    ]




    const handleSubmit = () => {
        axios.post("https://hooks.slack.com/triggers/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", {
            "first_name": firstName,
            "last_name": lastName,
            "email": email,
            "business_url": website,
            "business_name": businessName,
            "phone_number": phoneNumber,
            "industry": industry,
            "discovery": discoveryOptions.filter(option => option.value === discovery)[0].text,
            "if_discovery_other": discoveryOther === "" ? "N/A" : discoveryOther,
            "demo_type": demoTypeOptions.filter(option => option.value === demoType)[0].text,
            "if_demo_type_other": demoTypeOther === "" ? "N/A" : demoTypeOther,
            "origin": origin === "" ? "N/A" : origin,
            // "address_1": address1,
            // "address_2": address2,
            // "city": city,
            // "state": state,
            // "country": country,
            // "zip": zip,
        },
            {
                headers: { 'Content-type': 'application/x-www-form-urlencoded' }
            }
        ).then(function (response) {
            setShowSuccessMessage(true)
        }).catch(function (error) {
            console.log(error);
        });
    }
    const countryOptions = () => {
        const options = lookup.countries.map(country => {
            return {
                key: country.iso2,
                text: country.country,
                flag: country.iso2.toLowerCase(),
                value: country.country
            }
        })
        return options
    }


    return (
        <Layout>
            <AllRequestWrapper>
                {
                    wd && wd?.width && <>
                        <Grid
                            style={{
                                justifyContent: "center",
                                flexDirection: wd.width < 1101 ? "column" : "row",
                                alignItems: wd?.width < 1101 ? "center" : ""
                            }}
                            stackable
                            width={16}
                        >
                            <Grid.Column
                                width={wd?.width < 1101 ? 14 : 5}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    paddingRight: wd?.width < 1101 ? "" : "4rem"
                                }}
                            >
                                <Header
                                    style={{
                                        fontSize: "60px",
                                        width: "100%",
                                        textAlign: wd?.width < 1101 ? "center" : "left",
                                        whiteSpace: "nowrap",
                                        textWrap: "wrap"
                                    }}
                                >
                                    Request a {wd?.width > 1101 && <br />} Demo
                                </Header>
                                <List
                                    style={{
                                        width: "100%",
                                        fontFamily: "BrownLLSub-Regular",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                            marginTop: "2rem",
                                        }}
                                    >
                                        <div>
                                            <Circle
                                                size={12}
                                                style={{
                                                    marginRight: "1rem",
                                                    marginTop: "9px",
                                                    color: "red",
                                                    width: "39px",
                                                }}
                                            />
                                        </div>
                                        <List.Item>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    lineHeight: "1.75em",
                                                    marginLeft: "-1px",
                                                }}
                                            >
                                                Learn about Mickey's features including order management,
                                                an end-to-end purchasing solution, automated fulfillment,
                                                balance and payment invoicing, live tracking orders, and
                                                much more.
                                            </p>
                                        </List.Item>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <div>
                                            <Circle
                                                size={12}
                                                style={{
                                                    marginRight: "1rem",
                                                    marginTop: "9px",
                                                    color: "red",
                                                    width: "39px",
                                                }}
                                            />
                                        </div>
                                        <List.Item>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    lineHeight: "1.75em",
                                                    marginLeft: "-1px",
                                                }}
                                            >
                                                See how we can customize Mickey OS to match your business'
                                                needs
                                            </p>
                                        </List.Item>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "flex-start",
                                        }}
                                    >
                                        <div>
                                            <Circle
                                                size={12}
                                                style={{
                                                    marginRight: "1rem",
                                                    marginTop: "9px",
                                                    color: "red",
                                                    width: "39px",
                                                }}
                                            />
                                        </div>
                                        <List.Item>
                                            <p
                                                style={{
                                                    margin: 0,
                                                    lineHeight: "1.75em",
                                                    marginLeft: "-1px",
                                                }}
                                            >
                                                Get to know us more!
                                            </p>
                                        </List.Item>
                                    </div>

                                </List>
                            </Grid.Column>
                            <Grid.Column width={wd?.width < 1101 ? 14 : 9} style={{ paddingLeft: wd?.width < 1101 ? "" : "1.5rem" }}>
                                <Segment className="form-wrapper">
                                    <Form style={{ padding: ".5rem", display: showSuccessMessage ? "flex" : "", flexDirection: showSuccessMessage ? "column" : "", alignItems: showSuccessMessage ? "center" : "" }} size={"mini"} onSubmit={handleSubmit}>
                                        {!showSuccessMessage &&
                                            <>
                                                <Header>Business Information</Header>
                                                <Form.Input
                                                    required
                                                    placeholder="Legal Business Name"
                                                    label="Legal Business Name"
                                                    onChange={(e, d) => handleBusinessName(d?.value)}
                                                    type="text"
                                                />
                                                <Form.Group
                                                    widths="equal"
                                                    style={{
                                                        flexDirection: (wd?.width < 1230 && wd?.width > 1101) ? "column" : "row"
                                                    }}
                                                >
                                                    <Form.Input

                                                        style={{
                                                            marginBottom: (wd?.width < 1230 && wd?.width > 1101) ? "1em" : ""
                                                        }}
                                                        required
                                                        label="First Name"
                                                        type="text"
                                                        placeholder="First Name"
                                                        onChange={(e, d) => handleFirstName(d?.value)}
                                                    />
                                                    <Form.Input
                                                        required
                                                        label="Last Name"
                                                        type="text"
                                                        placeholder="Last Name"
                                                        onChange={(e, d) => handleLastName(d?.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group
                                                    widths="equal"
                                                    style={{
                                                        flexDirection: (wd?.width < 1230 && wd?.width > 1101) ? "column" : "row"
                                                    }}
                                                >
                                                    <Form.Input
                                                        style={{
                                                            marginBottom: (wd?.width < 1230 && wd?.width > 1101) ? "1em" : ""
                                                        }}
                                                        required
                                                        label="Email"
                                                        type="text"
                                                        placeholder="Email"
                                                        value={email}
                                                        onChange={(e, d) => handleEmail(d?.value)}
                                                    />

                                                    <PhoneInput
                                                        className="field"
                                                        country={"us"}
                                                        specialLabel="Phone"
                                                        disableDropdown={true}
                                                        countryCodeEditable={false}
                                                        placeholder={"Phone Number"}
                                                        onChange={(phone) => handlePhoneNumber(phone)}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths={(wd?.width < 1230 && wd?.width > 1101) ? "equal" : 2}>
                                                    <Form.Input
                                                        label="Business Url"
                                                        type="text"
                                                        placeholder="Business Url"
                                                        onChange={(e, d) => handleWebsite(d?.value)}
                                                    />
                                                    <Form.Input
                                                        label="Industry"
                                                        type="text"
                                                        placeholder="Industry"
                                                        onChange={(e, d) => handleIndustry(d?.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths={(wd?.width < 1230 && wd?.width > 1101) ? "equal" : 2}>
                                                    <Form.Dropdown
                                                        className="discovery-dropdown"
                                                        placeholder="How did you find us?"
                                                        label="How did you find us?"
                                                        selection
                                                        value={discovery}
                                                        options={discoveryOptions}
                                                        onChange={(e, d) => handleDiscovery(d?.value)}
                                                    />
                                                    <Form.Input
                                                        label="If Other please specify"
                                                        disabled={discovery !== "other"}
                                                        required={discovery === "other"}
                                                        type="text"
                                                        onChange={(e, d) => handleDiscoveryOther(d?.value)}
                                                    />
                                                </Form.Group>
                                                <Form.Group widths={(wd?.width < 1230 && wd?.width > 1101) ? "equal" : 2}>
                                                    <Form.Dropdown
                                                        className="discovery-dropdown"
                                                        placeholder="Demo Type"
                                                        label="Demo Type"
                                                        selection
                                                        value={demoType}
                                                        options={demoTypeOptions}
                                                        onChange={(e, d) => handleDemoType(d?.value)}
                                                    />
                                                    <Form.Input
                                                        label="If Other please specify"
                                                        disabled={demoType !== "other"}
                                                        required={demoType === "other"}
                                                        type="text"
                                                        onChange={(e, d) => handleDemoTypeOther(d?.value)}
                                                    />
                                                </Form.Group>


                                                {/* <Header>Business Address</Header>
                                        <Form.Group
                                            widths="equal"
                                            style={{
                                                flexDirection: (wd?.width < 1230 && wd?.width > 1101) ? "column" : "row"
                                            }}
                                        >
                                            <Form.Input
                                                style={{
                                                    marginBottom: (wd?.width < 1230 && wd?.width > 1101) ? "1em" : ""
                                                }}
                                                required
                                                label="Address 1"
                                                type="text"
                                                placeholder="Address 1"
                                                onChange={(e, d) => handleAddress1(d?.value)}
                                            />
                                            <Form.Input
                                                label="Address 2"
                                                type="text"
                                                placeholder="Address 2"
                                                onChange={(e, d) => handleAddress2(d?.value)}
                                            />
                                        </Form.Group>
                                        <Form.Group
                                            widths={"equal"}
                                            style={{
                                                flexDirection: (wd?.width < 1230 && wd?.width > 1101) ? "column" : "row"
                                            }}
                                        >
                                            <Form.Dropdown
                                                style={{
                                                    marginBottom: (wd?.width < 1230 && wd?.width > 1101) ? "1em" : ""
                                                }}
                                                className="homepage-dropdown"
                                                search
                                                fluid
                                                options={countryOptions()}
                                                required
                                                label="Country"
                                                type="text"
                                                placeholder="Country"
                                                onChange={(e, d) => handleCountry(d?.value)}
                                            />
                                            <Form.Input
                                                required
                                                label="State"
                                                type="text"
                                                placeholder="State"
                                                onChange={(e, d) => handleState(d?.value)}
                                            />

                                        </Form.Group>
                                        <Form.Group
                                            widths={"equal"}
                                            style={{
                                                flexDirection: (wd?.width < 1230 && wd?.width > 1101) ? "column" : "row"
                                            }}
                                        >
                                            <Form.Input
                                                style={{
                                                    marginBottom: (wd?.width < 1230 && wd?.width > 1101) ? "1em" : ""
                                                }}
                                                required
                                                label="City"
                                                type="text"
                                                placeholder="City"
                                                onChange={(e, d) => handleCity(d?.value)}
                                            />
                                            <Form.Input
                                                required
                                                label="Zip"
                                                type="text"
                                                placeholder="Zip"
                                                onChange={(e, d) => handleZip(d?.value)}
                                            />
                                        </Form.Group> */}

                                                {
                                                    error &&
                                                    <Message negative>
                                                        <Message.Header>
                                                            Invalid Information
                                                        </Message.Header>
                                                        <Message.Content>
                                                            {error}
                                                        </Message.Content>
                                                    </Message>
                                                }
                                                <Button
                                                    className="submit-button"
                                                    type="submit"
                                                    style={{ marginTop: "1rem" }}
                                                    disabled={
                                                        (businessName && firstName && lastName && email && phoneNumber) === "" ||
                                                        (businessName && firstName && lastName && email && phoneNumber) === null ||
                                                        (businessName && firstName && lastName && email && phoneNumber) === undefined
                                                    }
                                                >
                                                    Request Demo
                                                </Button>
                                            </>
                                        }
                                        {showSuccessMessage &&
                                            <Header icon>
                                                <Icon color={'green'} name={'check'} />
                                                Thanks for requesting a demo! We'll get back to you shortly!
                                            </Header>
                                        }
                                    </Form>
                                </Segment>
                            </Grid.Column>
                            {/* </Grid.Row> */}
                        </Grid>
                    </>
                }
            </AllRequestWrapper>
        </Layout >
    )
}

export default RequestDemo
