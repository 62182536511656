import React, { useState } from 'react'
import styled from "styled-components";
import { Grid, Button, Header, Input } from "semantic-ui-react";
import { Fade } from "react-reveal"
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Slide } from 'react-slideshow-image';
import { CaretLeft, CaretRight } from 'phosphor-react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from "../../v2/Layout/layout2"
import forest from '../../../images/forest.jpg';
import ExpertTechnology from '../../../images/computer.jpg';
import ExpertLogistics from '../../../images/logistics.jpg';
import WestonPng from '../../../images/Weston.png';
import RabensPng from '../../../images/Rabens.png';
import useWindowDimensions from '../../../hooks/useWindowDimensions';
import Vision from "../../../images/about-vision.png"
import Technology from "../../../images/about-tech.png"
import RequestImg from "../../../images/requestimg.webp"
import { media } from '../../../../mq';


const AboutWrapper = styled.div`
  padding: 3rem 0 3rem 0;
  margin: 0;
  ${media.smaller`padding: 5rem 3rem;`}
  .section-container{
    padding: 0;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    ${media.smaller`margin-bottom: 100px; `}
    ${media.smallMedium`flex-direction: row;`}
    ${media.xl`justify-content: center;`}
    &#about-heading{
        justify-content: center;
        ${media.smallMedium`justify-content: flex-start;`}
        .about-header-wrapper{
            position: relative;
            .about-header{
                text-align: center;
                line-height: 1.5em;
                ${media.smallMedium`text-align: left;`}
                span{
                    position: relative;
                    div{
                        display: none;
                        width: 153px;
                        height: 8px;
                        position: absolute;
                        background-color: #f1592a;
                        right: -160px;
                        ${media.smallMedium`display: block;`}
                    }
                }
            }
        }
    }
    &#about-stats{
        flex-direction: column;
        margin-top: 150px;
        margin-bottom: 150px;
        ${media.smaller`margin-top: 300px;`}
        ${media.smallMedium`flex-direction: row;`}
        .ui.header.mickey-stat-header{
            color: #f1592a;
            font-size: 64px;
            font-family: "Merriweather-Regular" !important;
            .sub.header.mickey-stat-subheader{
                font-size: 20px;
                font-family: "BrownLLSub-Regular";
            }
        }
    }
    &#our-vision{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        ${media.medium`flex-direction: row;`}
        .automated-left{}
        .automated-right{
            .our{
                color: #f1592a;
                font-size: 16px;
                text-align: center;
                ${media.small`text-align: left;`}
            }
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
                p{
                    font-size: 18px;
                    line-height: 2em;
                    margin-top: 2rem;
                    font-family: "Brown Regular"
                }
            }
        }
    }
    &#our-tech{
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        ${media.medium`flex-direction: row;`}
        .os-left{
            .our{
                color: #f1592a;
                font-size: 16px;
                text-align: center;
                ${media.small`text-align: left;`}
            }
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
                p{
                    font-size: 18px;
                    line-height: 2em;
                    margin-top: 2rem;
                    font-family: "Brown Regular"
                }
            }
        }
        .os-right{}
    }
    &#our-story{
        flex-direction: column;
        justify-content: center;
        ${media.smallMedium`flex-direction: row;`}
        .our-story-inner-wrapper{
            .our{
                color: #f1592a;
                font-size: 16px;
                text-align: center;
                ${media.small`text-align: left;`}
            } 
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
                p{
                    font-size: 18px;
                    line-height: 2em;
                    margin-top: 2rem;
                    font-family: "Brown Regular";
                    text-align: center;
                    ${media.small`text-align: left;`}
                }
            }
        }
    }
    &#experts{
        display: flex;
        justify-content: center;
        align-items: center;
        ${media.smallMedium`align-items: flex-start;`}
        .experts-inner-wrapper{
            flex-direction: column;
            width: 100%;
            ${media.smallMedium`flex-direction: row;`}
            ${media.xl`max-width: 87.5%;`}
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
            }
            .experts-row{
                justify-content: center;
                .expert{
                    margin-bottom: 2rem;
                    ${media.smallMedium`margin-bottom: 0;`}
                    img{
                        width: 100%;
                        height: auto;
                    }
                    h5{
                        p{
                            font-size: 16px;
                            line-height: 2em;
                            margin-top: 2rem;
                            font-family: "Brown Regular";
                        }
                    }
                }
            }
        }
    }
    &#leadership{
        flex-direction: column;
        min-height: 500px;
        align-items: center;
        justify-content: center;
        ${media.smallMedium`flex-direction: row;`}
        .leadership-left{
            margin-bottom: 2rem;
            min-height: inherit;
            ${media.smallMedium`margin-bottom: 0;`}
            .orange-button-wrapper{
                justify-content: center;
                ${media.small`justify-content: flex-start;`}
            }
            h2{
                text-align: center;
                ${media.small`text-align: left;`}
                p{
                    font-size: 16px;
                    line-height: 2em;
                    margin-top: 2rem;
                    font-family: "Brown Regular";
                }
            }
        }
        .leadership-right{
            min-height: inherit;
            .images-wrapper{
                display: flex;
                min-height: inherit;
                flex-direction: column;
                margin-top: 3rem;
                ${media.small`flex-direction: row; margin-top: 0;`}
                .image-left{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    flex: 0 1 50%;
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 3rem;
                        ${media.small`margin-bottom: 0;`}
                        img{
                            width: 300px;
                            height: auto;
                        }
                        .name{
                            margin-top: 1rem;
                            font-size: 18px;
                            .position{
                                font-family: "Brown Regular";
                                margin-top: .5rem;
                            }
                        }
                    }
                    
                }
                .image-right{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    flex: 0 1 50%;
                    div{
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        margin-bottom: 3rem;
                        ${media.small`margin-bottom: 0;`}
                        img{
                            width: 300px;
                            height: auto;
                        }
                        .name{
                            margin-top: 1rem;
                            font-size: 18px;
                            .position{
                                font-family: "Brown Regular";
                                margin-top: .5rem;
                            }
                        }
                    }
                    
                }
            }

        }
    }
    &#backed-by-investors{
        justify-content: center;
        h2{
            margin-bottom: 2rem;
            text-align: center;
            ${media.small`text-align: left;`}
        }
    }
    &#request-demo{
        justify-content: space-around;
        flex-direction: column;
        align-items: center;
        ${media.large`flex-direction: row;`}
        ${media.xl`justify-content: center;`}
        .request-demo-header{
            width: 100%;
            ${media.large`width: 80%;`}
            .request-demo-subheader{
                font-size: 16px;
                line-height: 1.8em;
                margin-bottom: 2rem;
                margin-top: 2rem;
            }
        }
        .request-image-wrapper{
            text-align: center;
            margin-top: 4rem;
            ${media.large`text-align: right; margin-top: 0;`}
                img{
                width: 100%;
                height: auto;
            }
        }
        .orange-button-wrapper{
            margin-bottom: 0;
            ${media.small`justify-content: flex-start;`}
        }
        .request-wrapper{
            display: flex;
            flex-direction: column;
            align-items: center;
            ${media.small`flex-direction: row;`}
            .ui.input{
                border-radius: 50px;
                border: 1px solid #6d6e70;
                width: 100%;
                margin-right: 0;
                margin-bottom: 2rem;
                ${media.small`width: 300px; margin-right: 2rem; margin-bottom: 0;`}
                input{
                    border-radius: 50px;
                    border: none;
                    width: inherit;
                }
            }
        }
    }
  }
  .indicators > li{
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
  .react-slideshow-wrapper .images-wrap{
    align-items: center;
  }

  h1,h2{
    font-size: 36px !important;
    ${media.smaller`font-size: 48px !important;`}
  }
  .orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }
`


const AboutCompany = () => {
    const data = useStaticQuery(graphql`
    query AboutQuery {
      allWpInvestorLogo {
        edges {
          node {
            investorLogos {
              investorLogo {
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED, quality: 100, width: 300)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

    const wd = useWindowDimensions()

    const [email, setEmail] = useState("")

    const handleEmail = (inputEmail) => {
        setEmail(inputEmail)
    }

    const properties = {
        prevArrow:
            <CaretLeft size={32} color="#2b2727" />

        ,
        nextArrow:
            <CaretRight size={32} color="#2b2727" />
    }

    const responsiveSettings = [
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 1000,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        },

    ];
    const investorLogos = data?.allWpInvestorLogo?.edges


    return (
        <Layout>

            <AboutWrapper>
                {
                    wd && wd?.width && <>
                        <Grid
                            width={16}
                            stackable
                            className='section-container'
                            id="about-heading"
                        >
                            <Grid.Column
                                className='about-header-wrapper'
                                width={wd.width <= 991 ? 16 : 12}
                            >
                                <Header
                                    className='about-header'
                                    as={"h1"}
                                >
                                    Mickey is a technology company that builds ecommerce
                                    marketplaces and platforms for wholesale and commodity {" "}
                                    <span>
                                        products.
                                        <div />
                                    </span>
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <Grid
                            stackable
                            width={16}
                            className="section-container"
                            id="about-stats"
                        >
                            <Grid.Column width={wd.width <= 991 ? 16 : 4}>
                                <Fade top>
                                    <Header as={"h2"} textAlign="center" className='mickey-stat-header'>
                                        1500+
                                        <Header.Subheader as={"p"} className="mickey-stat-subheader">
                                            Shipments
                                        </Header.Subheader>
                                    </Header>
                                </Fade>
                            </Grid.Column>
                            <Grid.Column width={wd.width <= 991 ? 16 : 4}>
                                <Fade top>
                                    <Header as={"h2"} textAlign="center" className='mickey-stat-header'>
                                        13
                                        <Header.Subheader as={"p"} className="mickey-stat-subheader">
                                            Venture Partners
                                        </Header.Subheader>
                                    </Header>
                                </Fade>
                            </Grid.Column>
                            <Grid.Column width={wd.width <= 991 ? 16 : 4}>
                                <Fade top>
                                    <Header as={"h2"} textAlign="center" className='mickey-stat-header'>
                                        17
                                        <Header.Subheader as={"p"} className="mickey-stat-subheader">
                                            Employees
                                        </Header.Subheader>
                                    </Header>
                                </Fade>
                            </Grid.Column>
                            <Grid.Column width={wd.width <= 991 ? 16 : 4}>
                                <Fade top>
                                    <Header as={"h2"} textAlign="center" className='mickey-stat-header'>
                                        $11m
                                        <Header.Subheader as={"p"} className="mickey-stat-subheader">
                                            Raised
                                        </Header.Subheader>
                                    </Header>

                                </Fade>
                            </Grid.Column>
                        </Grid>
                        <Grid
                            stackable
                            width={16}
                            className="section-container"
                            id="our-vision"
                        >
                            <Grid.Column className='automated-left' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                                <img src={Vision} />
                            </Grid.Column>
                            <Grid.Column className='automated-right' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                                <p className='our'>Our Vision</p>
                                <Header as={"h2"}>
                                    Automated Technology for B2B businesses
                                    <Header.Subheader as={"p"}>
                                        Since 2019, Mickey has been focused on modernizing the antiquated, day-to-day sales and trading operations of traditional, North American natural resource products. Mickey is on a mission to build ecommerce platforms for B2B businesses with modern technologies. We believe in helping commodities and other wholesale, B2B companies to scale their business with our streamlined, easy-to-use platform built specifically for their industry.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <Grid
                            stackable
                            width={16}
                            className="section-container"
                            id="our-tech"
                        >
                            <Grid.Column className='os-left' width={wd.width >= 1025 ? 5 : 8 && wd.width <= 1025 ? 16 : 8}>
                                <p className='our'>Our Technology</p>
                                <Header as={"h2"}>
                                    Mickey OS
                                    <Header.Subheader as={"p"}>
                                        In 2022, Mickey built ‘Mickey OS,’ an operating system that can be integrated into a suppliers website to handle all aspects of a commodity e-commerce transaction (including order and payment processing, inventory management and logistics). Mickey’s expert team of engineers, developers, and data professionals, can build an ecommerce marketplace for wholesale and other commodity products that traditionally have been mired in outdated sales processes.
                                    </Header.Subheader>
                                </Header>
                                <div className='orange-button-wrapper'>
                                    <Button
                                        className="orange-button"
                                        href="/products"
                                    >
                                        Learn More
                                    </Button>
                                </div>
                            </Grid.Column>
                            <Grid.Column className='os-right' width={wd.width >= 1025 ? 9 : 8 && wd.width <= 1025 ? 16 : 8}>
                                <img src={Technology} />
                            </Grid.Column>
                        </Grid>
                        <Grid
                            stackable
                            width={16}
                            className="section-container"
                            id="our-story"
                        >
                            <Grid.Column className='our-story-inner-wrapper' width={wd.width <= 980 ? 16 : 10}>
                                <p className='our'>Our Story</p>
                                <Header textAlign="center" as={"h2"}>
                                    Rooted in Forest Products
                                    <Header.Subheader as={"p"}>
                                        When Mickey first launched, we had an initial focus on
                                        bringing the physical commodity trading process online and
                                        allowing suppliers and traders around the world to transact
                                        openly. Mickey, which had its roots in forest products, built
                                        a digital platform that modernized the complete commodity
                                        transaction -- from sourcing and shipping to invoicing and
                                        payments -- providing a marketplace that's fast and easy for
                                        global buyers to purchase, track, and acquire the commodities
                                        they needed.
                                        <br />
                                        <br />
                                        Since our launch, we have raised more than $10.7M to date and
                                        are financially backed by Lerer Hippeau, LightBank, UpHonest,
                                        and others. A small team with a wide range of expertise
                                        including tech, software, engineering, lumber, commodities
                                        trading, sales, marketing, and more.
                                        <br />
                                        <br />
                                        Mickey is led by experts from a wide range of expertise
                                        including tech, software, engineering, trading, sales, design,
                                        marketing, and more.
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <div
                            className="section-container"
                            id="experts"
                        >
                            <Grid
                                className='experts-inner-wrapper'
                                stackable
                            >
                                <Header as={"h2"}>
                                    We have experts in:
                                </Header>
                                <Grid.Row className='experts-row'>
                                    <Grid.Column className='expert' width={wd.width <= 980 ? 16 : 5}>
                                        <img
                                            src={ExpertTechnology}
                                        />
                                        <Header as={"h5"}>
                                            Technology
                                            <Header.Subheader as={"p"}>
                                                B2B businesses looking to upgrade their ecommerce platforms can utilize our Mickey OS, an operating system that can be integrated into a suppliers website to handle all aspects of a commodity ecommerce transaction (including order and payment processing, inventory management and logistics).
                                            </Header.Subheader>
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column className='expert' width={wd.width <= 980 ? 16 : 5} >
                                        <img
                                            src={ExpertLogistics}
                                        />
                                        <Header as={"h5"}>
                                            Logistics
                                            <Header.Subheader as={"p"}>
                                                Mickey has partnered with several of the country’s leading trucking and logistics leaders to streamline the pick up and delivery process. With Mickey you and your buyers can track real-time delivery updates making it easier for buyers to plan for deliveries and order completion.
                                            </Header.Subheader>
                                        </Header>
                                    </Grid.Column>
                                    <Grid.Column className='expert' width={wd.width <= 980 ? 16 : 5} >
                                        <img
                                            src={forest}
                                        />
                                        <Header as={"h5"}>
                                            Service
                                            <Header.Subheader as={"p"}>
                                                Mickey’s technology enables suppliers to accept multiple payment types with instant payment processing as well as pay-over-time options. Our technology can help generate sales and order reports along with showing live inventory updates – all at the touch of a button.
                                            </Header.Subheader>
                                        </Header>
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div>
                        <Grid
                            stackable
                            width={wd.width >= 1600 ? 14 : 16}
                            className="section-container"
                            id="leadership"
                        >

                            <Grid.Column className='leadership-left' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                                <Header as={"h2"}>
                                    Strong Leadership.<br />
                                    Discerning Vision.
                                    <Header.Subheader as={"p"}>
                                        Mickey was founded by Alex Rabens who leads the company as its CEO. In 2022, Weston Stewart-Tennes was named as its CTO. The organization currently has 12 team members and 9 others across its divisional businesses.
                                    </Header.Subheader>
                                </Header>
                                <div className='orange-button-wrapper'>
                                    <Button
                                        className="orange-button"
                                        href="/team"
                                        style={{
                                            height: "40px"
                                        }}
                                    >
                                        Meet Our Entire Team
                                    </Button>
                                </div>

                            </Grid.Column>
                            <Grid.Column className='leadership-right' width={wd.width >= 1025 ? 7 : 8 && wd.width <= 1025 ? 16 : 8}>
                                <div className='images-wrapper'>
                                    <div className='image-left'>
                                        <div>
                                            <img src={RabensPng} />
                                            <Header className='name' textAlign='center' as={"p"}>
                                                Alex Rabens
                                                <Header.Subheader
                                                    className='position'
                                                    textAlign="center"
                                                    as={"p"}
                                                >
                                                    Founder & CEO
                                                </Header.Subheader>
                                            </Header>
                                        </div>
                                    </div>
                                    <div className='image-right'>
                                        <div>
                                            <img src={WestonPng} />
                                            <Header className='name' textAlign='center' as={"p"}>
                                                Weston Stewart-Tennes
                                                <Header.Subheader
                                                    className='position'
                                                    textAlign="center"
                                                    as={"p"}
                                                >
                                                    CTO
                                                </Header.Subheader>
                                            </Header>
                                        </div>
                                    </div>
                                </div>
                            </Grid.Column>
                        </Grid>
                        <Grid
                            stackable
                            className="section-container"
                            id="backed-by-investors"
                            width={wd.width >= 1600 ? 14 : 16}
                        >
                            <Grid.Column width={wd.width >= 1600 ? 14 : 16}>
                                <Header as={"h2"}>
                                    Backed By Investors
                                </Header>
                                <Slide
                                    duration={0}
                                    transitionDuration={5000}
                                    slidesToShow={wd.width <= 620 ? 1 : 4}
                                    slidesToScroll={1}
                                    responsive={responsiveSettings}
                                    indicators={false}
                                    autoplay={true}
                                    {...properties}
                                    arrows={false}
                                >
                                    {
                                        investorLogos?.map((logo, index) => {
                                            const image = getImage(logo.node.investorLogos.investorLogo.localFile)
                                            return (
                                                <div style={{ width: "250px" }}>
                                                    <GatsbyImage
                                                        image={image}
                                                    />
                                                </div>
                                            )
                                        })
                                    }
                                </Slide>
                            </Grid.Column>
                        </Grid>
                        <Grid
                            className="section-container"
                            id="request-demo"
                            stackable
                            width={16}
                        >
                            <Grid.Column
                                width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}
                            >
                                <Header
                                    className="request-demo-header"
                                    as={"h2"}
                                    textAlign={wd.width <= 768 ? "center" : "left"}
                                >
                                    Request a demo with Mickey today.
                                    <Header.Subheader
                                        className="request-demo-subheader"
                                        as={"p"}
                                    >
                                        Request a demo with us to better understand how Mickey can be implemented at your company.
                                    </Header.Subheader>
                                </Header>
                                <div className="request-wrapper">
                                    <Input
                                        placeholder={"Enter Email..."}
                                        onChange={(e, d) => handleEmail(d?.value)}
                                    />
                                    <div className="orange-button-wrapper">
                                        <Button
                                            className="orange-button"
                                            href={`/request-demo/?email=${email}&origin=About%20Page&demoType=mickey-os`}
                                        >
                                            Request Demo
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                className="request-image-wrapper"
                                width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 7}
                            >
                                <img
                                    src={RequestImg}
                                />
                            </Grid.Column>
                        </Grid>
                    </>
                }
            </AboutWrapper>
        </Layout >
    )
}

export default AboutCompany