import React, { useState } from 'react'
import Layout from '../../v2/Layout/layout2'
import { Header, Message, Form, TextArea, Button, Grid } from "semantic-ui-react";
import styled from 'styled-components';
import useWindowDimensions from "../../../hooks/useWindowDimensions"
import axios from 'axios';
import { emailValidator } from '../../../utils/utils';

const SupportWrapper = styled.div`
width: 100%;
height: 100%;
min-height: 70vh;
padding: 4rem 0 2rem 0;
.grid{
    min-height: inherit;
    align-items: center !important;
}
.form{
    label{
        font-size: 12px;
        color: rgb(130 130 130);
        text-transform: none !important;
    }
    button{
        width: 100%;
        color: white;
        border-radius: 500px;
        background-color: #f1592a;
        transition: .1s ease-in-out;
        border: 1px solid #f1592a;
        &:hover{
            background-color: white;
            color: #f1592a
        }
    }
    .field{
        padding: 0;
        display: flex;
        flex-direction: column;
        align-items: baseline;
        label{
            font-size: 12px !important;
            text-transform: none !important;
        }
    }
    textarea{
        background-color: rgb(250, 250, 250) !important;
        resize: none !important;
    }
}
`

const ResourcesSupport = () => {
    const windowDimensions = useWindowDimensions()
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [email, setEmail] = useState("")
    const [businessName, setBusinessName] = useState("")
    const [message, setMessage] = useState("")
    const [error, setError] = useState("")

    const handleFirstName = (inputFirstName) => {
        setFirstName(inputFirstName)
    }
    const handleLastName = (inputLastName) => {
        setLastName(inputLastName)
    }
    const handleBusinessName = (inputBusinessName) => {
        setBusinessName(inputBusinessName)
    }
    const handleMessage = (inputMessage) => {
        setMessage(inputMessage)
    }
    const handleEmail = (inputEmail) => {
        const errorMessage = emailValidator(inputEmail)
        if (!errorMessage) {
            setEmail(inputEmail)
            setError(null)
        } else {
            setError(errorMessage)
        }
    }

    const handleSubmit = () => {
        axios.post("https://hooks.slack.com/workflows/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", {
            "first_name": firstName,
            "email": email,
            "message": message,
            "last_name": lastName,
            "company_name": businessName,
        },
            {
                headers: { 'Content-type': 'application/x-www-form-urlencoded' }
            }
        ).then(function (response) {
            console.log(response);
        })
            .catch(function (error) {
                console.log(error);
            });
    }
    return (
        <Layout>
            <SupportWrapper>
                <Grid textAlign='center'>
                    <Grid.Column width={windowDimensions.width > 1100 ? 7 : 10}>
                        <Header as={"h1"}>
                            Contact Us
                        </Header>
                        <Form onSubmit={handleSubmit}>
                            <Form.Group widths={2}>
                                <Form.Input
                                    required
                                    label="First Name"
                                    placeholder="First Name"
                                    onChange={(e, d) => handleFirstName(d?.value)}
                                />
                                <Form.Input
                                    required
                                    label="Last Name"
                                    placeholder="Last Name"
                                    onChange={(e, d) => handleLastName(d?.value)}
                                />
                            </Form.Group>
                            <Form.Group widths={2}>
                                <Form.Input
                                    required
                                    label="Company Name"
                                    placeholder="Company Name"
                                    onChange={(e, d) => handleBusinessName(d?.value)}
                                />
                                <Form.Input
                                    required
                                    label="Email"
                                    placeholder="Email"
                                    onChange={(e, d) => handleEmail(d?.value)}
                                />
                            </Form.Group>
                            {
                                error &&
                                <Message negative>
                                    <Message.Header>
                                        Invalid Information
                                    </Message.Header>
                                    <Message.Content>
                                        {error}
                                    </Message.Content>
                                </Message>
                            }
                            <Form.TextArea
                                required
                                label="Message"
                                rows={5}
                                onChange={(e, d) => handleMessage(d?.value)}
                            />
                            <Button
                                type='submit'
                                className="submit-button"
                                disabled={
                                    (businessName && firstName && lastName && email && message) === "" ||
                                    (businessName && firstName && lastName && email && message) === null ||
                                    (businessName && firstName && lastName && email && message) === undefined
                                }
                            >
                                Submit
                            </Button>
                        </Form>
                    </Grid.Column>
                </Grid>
            </SupportWrapper>
        </Layout >
    )
}

export default ResourcesSupport